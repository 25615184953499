<template>
  <div class="app">
    <ul class="icons clearfix">
      <li v-for="icon in icons" :key="icon">
        <svgIcon :icon="icon"></svgIcon>
        <p>{{ icon }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import svgIcon from "@/components/svg.vue";
export default {
  name: "Icon",
  components: {
    svgIcon
  },
  data() {
    return {
      icons: [
        "icon-vote",
        "icon-info2",
        "icon-info3",
        "icon-olympics",
        "icon-gaelic-sports",
        "icon-horse-racing",
        "icon-formula-1",
        "icon-chess",
        "icon-badminton",
        "icon-darts",
        "icon-cricket",
        "icon-table-tennis",
        "icon-snooker",
        "icon-specials",
        "icon-cycling",
        "icon-american-football",
        "icon-winter-sports",
        "icon-aussie-rules",
        "icon-rugby",
        "icon-motosport",
        "icon-boxing",
        "icon-golf",
        "icon-floorball",
        "icon-handball",
        "icon-tennis",
        "icon-ice-hockey",
        "icon-baseball",
        "icon-basketball",
        "icon-soccer",
        "icon-bet-slip",
        "icon-virtual-sport",
        "icon-mma",
        "icon-dota-2",
        "icon-counter-strike",
        "icon-esport",
        "icon-rally",
        "icon-aquatics",
        "icon-rugby-league",
        "icon-waterpolo",
        "icon-pool",
        "icon-field-hockey",
        "icon-volleyball",
        "icon-sport2",
        "icon-loading",
        "icon-gif",
        "icon-metamask",
        "icon-google-auth",
        "icon-binance",
        "icon-tronwallet",
        "icon-coin98",
        "icon-chat-ban-info",
        "icon-vip-crown-orange",
        "icon-data-hidden",
        "icon-chat-close-reply-mode",
        "icon-tips",
        "like-heart",
        "icon-link",
        "icon-lock-vault",
        "icon-locked",
        "icon-locked-new",
        "icon-livechat",
        "icon-dollar",
        "icon-clock",
        "icon-curve-arrow",
        "icon-all-games-full",
        "icon-casino",
        "icon-sport",
        "icon-fairness",
        "icon-rating",
        "icon-sound-on",
        "icon-sound-off",
        "icon-datepicker",
        "icon-info",
        "icon-star-full",
        "icon-star-half",
        "icon-star",
        "icon-close",
        "icon-fullscreen",
        "icon-wide",
        "icon-table-user",
        "icon-question",
        "icon-question-1",
        "icon-arrow",
        "icon-email",
        "icon-speed",
        "icon-rules",
        "icon-animation",
        "icon-hotkeys",
        "icon-calendar",
        "icon-campaign",
        "icon-plus-rounded",
        "icon-error-triangle",
        "icon-pen",
        "icon-blocked-bonus",
        "icon-trash",
        "icon-arrow-down",
        "icon-arrow-up",
        "icon-arrow-left",
        "icon-arrow-right",
        "icon-inhouse",
        "icon-top",
        "icon-new",
        "icon-success",
        "icon-providers",
        "icon-menu-cashback",
        "icon-menu-networks",
        "icon-menu-vip",
        "icon-menu-about",
        "icon-wallet",
        "icon-delete",
        "icon-search-new",
        "icon-settings-new",
        "icon-tourney-new",
        "icon-bonus",
        "icon-statistic",
        "icon-statistic-1",
        "icon-cup",
        "icon-chat-send",
        "icon-chat-reply",
        "icon-chat-tag",
        "icon-chat-delete",
        "icon-chat-pin",
        "icon-chat-mute",
        "icon-download",
        "icon-copy",
        "icon-update",
        "icon-arrow-down-full",
        "icon-arrow-up-full",
        "icon-check-small",
        "icon-joystick-small",
        "icon-cross-small",
        "icon-eye-open",
        "icon-eye-close",
        "icon-chat",
        "icon-email-new",
        "icon-share",
        "icon-checkmark-circle",
        "icon-checkmark",
        "icon-timer",
        "icon-filter",
        "icon-edit",
        "icon-contacts",
        "icon-account",
        "icon-cashback",
        "icon-global-tips",
        "icon-vault",
        "icon-transaction",
        "icon-history",
        "icon-history-expired",
        "icon-sessions",
        "icon-wallet-account",
        "icon-swap",
        "icon-rain",
        "icon-bets",
        "icon-live-chat",
        "icon-share-download",
        "icon-birthday",
        "icon-secured",
        "icon-gift",
        "icon-promocode",
        "icon-telegram-auth",
        "icon-crown",
        "icon-hearth",
        "icon-rocket",
        "icon-joystick",
        "icon-circle-arrow",
        "icon-coin-drop",
        "icon-smile",
        "icon-more",
        "icon-limbo",
        "icon-coinflip",
        "icon-circle",
        "icon-mines",
        "icon-dice",
        "icon-spacedice",
        "icon-plinko",
        "icon-keno",
        "icon-hilo",
        "icon-stairs",
        "icon-crash",
        "icon-cryptos",
        "icon-tower",
        "icon-furywild",
        "icon-triple",
        "icon-ring",
        "icon-slots",
        "icon-live",
        "icon-table",
        "icon-special",
        "icon-question-mark-rounded",
        "icon-earn",
        "icon-farming",
        "icon-staking",
        "icon-boxes",
        "icon-referral",
        "icon-news",
        "icon-promotions",
        "icon-funfury-competition",
        "icon-battle",
        "icon-telegram",
        "icon-twitter",
        "icon-instagram",
        "icon-facebook",
        "icon-steemit",
        "icon-reddit",
        "icon-tiktok",
        "icon-discord",
        "icon-pinterest",
        "icon-bitcointalk",
        "icon-youtube",
        "icon-chat-2",
        "icon-bell-full",
        "icon-arrow-left-small",
        "icon-arrow-right-small",
        "icon-play",
        "icon-live-support",
        "icon-user",
        "icon-menu-promotions",
        "icon-hamburger",
        "icon-hamburger-default",
        "icon-hamburger-open",
        "icon-book",
        "icon-table-page",
        "icon-paylines",
        "icon-database",
        "icon-arrow-down-small",
        "icon-stop",
        "icon-infinitely",
        "icon-mail",
        "icon-indefinite",
        "icon-dice-front",
        "icon-heart-new",
        "icon-tournament",
        "icon-play-new",
        "icon-line",
        "icon-info-circle",
        "sport-soccer",
        "ic-badge-hot",
        "sport-fifa-b",
        "sport-tennis",
        "sport-basketball",
        "sport-nba2k-b",
        "sport-esport",
        "sport-americanfootball",
        "sport-rocket-league-b",
        "sport-baseball",
        "sport-icehockey",
        "sport-volleyball",
        "sport-handball",
        "sport-tabletennis",
        "sport-e-fighting-b",
        "sport-aotennis-b",
        "sport-boxing",
        "sport-aussierules",
        "sport-rugby",
        "sport-formula-1",
        "sport-baseball-b",
        "sport-ecricket-b",
        "sport-mma",
        "sport-snooker",
        "sport-waterpolo",
        "sport-cricket",
        "sport-skirace",
        "sport-floorball",
        "sport-golf",
        "sport-cycling",
        "sport-specials",
        "sport-darts",
        "sport-beachvolleyball",
        "sport-lacrosse",
        "sport-alpineskiing",
        "sport-biathlon",
        "sport-skijumping",
        "sport-indycar",
        "sport-speedway",
        "sport-gaelicfootball",
        "sport-gaelichurling",
        "sport-formula_e",
        "sport-bikes",
        "sport-stockcarracing",
        "icon-gold"
      ]
    };
  },
  methods: {

  },
  mounted() {
  }
};
</script>
<style scoped>
.icons li {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  border: 1px solid #999;
  text-align: center;
}
</style>
